import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Img from 'gatsby-image'

const ServicesGrid = ({ locale, gridItems, filter }) => (
  <div className="columns">
    {gridItems &&
      gridItems
        .map((item) => (
          <Link
            to={locale === "en" ? `/${item.link}/` : `/${locale}/${item.link}/`}
            key={item.header}
            className="column"
          >
            <Img
              fluid={item.image.childImageSharp.fluid}
              alt={item.header}
              title={item.header}
            />
            <h3>{item.header}</h3>
            <span />
          </Link>
        ))}
  </div>
);

ServicesGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      header: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

export default ServicesGrid;
