import React from "react";
import PropTypes from "prop-types";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const AccordionGrid = ({ title, content }) => (
  <div className="accordion">
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <h4>{title}</h4>
      </AccordionSummary>
      <AccordionDetails>
        <div
          className="accordion-content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </AccordionDetails>
    </Accordion>
  </div>
);

AccordionGrid.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

export default AccordionGrid;
